// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
   background: linear-gradient(87deg, #040404 0, #040404 100%) !important;
}

.bg-default {
   background: linear-gradient(#6A6A6A 0, #6A6A6A 100%) !important;
}

.fill-default {
   fill: #6A6A6A !important;
}`, "",{"version":3,"sources":["webpack://./src/layouts/auth.css"],"names":[],"mappings":"AAAA;GACG,sEAAsE;AACzE;;AAEA;GACG,+DAA+D;AAClE;;AAEA;GACG,wBAAwB;AAC3B","sourcesContent":[".content {\n   background: linear-gradient(87deg, #040404 0, #040404 100%) !important;\n}\n\n.bg-default {\n   background: linear-gradient(#6A6A6A 0, #6A6A6A 100%) !important;\n}\n\n.fill-default {\n   fill: #6A6A6A !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
