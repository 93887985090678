import React, { useState } from "react";

import { Table } from "reactstrap";

function CustomTable(props) {
  const [activeDetail, setActiveDetail] = useState(false)
  console.log(props)
  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          {props.columns.map((column, index) => (
            <th key={index} scope="col">
              {column}
            </th>
          ))}
          <th scope="col" />
        </tr>
      </thead>

      <tbody>
        {props.rows.map((row, index) => (
          <tr key={index}>
            {row.map((data, dataIndex) => (
              <>
                <td key={dataIndex}>
                  {data}
                </td>
              </>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default CustomTable;
