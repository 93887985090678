/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useGetVestingList } from "utils/user.api";

const Header = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetVestingList()
  const [filteredData, setFilteredData] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)

  function groupByTransactionDate(dataList) {
    const groupedData = {};
    dataList.forEach(item => {
      const { transactionDate } = item;
      if (!groupedData[transactionDate]) {
        groupedData[transactionDate] = [];
      }

      groupedData[transactionDate].push(item);
    });

    const groupedList = Object.values(groupedData);
    return groupedList
  }



  useEffect(() => {

    if (data) {
      const total = data.reduce((acc, cur) => acc + cur?.amount, 0)
      setTotalAmount(total)
      console.log({ totalAmount })
      const newValue = groupByTransactionDate(data)
      setFilteredData(newValue)
    }
  }, [data])

  const vesting_dummy = [
    {
      date: new Date(),
      amount_to_send: 20000,
      transactions: 100
    },
    {
      date: new Date(),
      amount_to_send: 30000,
      transactions: 200
    },
    {
      date: new Date(),
      amount_to_send: 234234,
      transactions: 300
    }
  ]

  const RequiredToken = vesting_dummy.reduce((acc, cur) => acc + cur.amount_to_send, 0)

  function formatNumberWithCommas(number) {
    // Use toLocaleString to add commas
    if (!number) return 0
    return Number(number).toLocaleString();
  }



  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  onClick={() => navigate("/admin/coins-list")}
                  style={{ cursor: "pointer" }}
                >
                  <CardBody>
                    <Row>
                      <Col className="d-flex align-items-center">
                        <CardTitle
                          tag="h4"
                          className="text-uppercase text-muted mb-0 align-center"
                        >
                          Coins List
                        </CardTitle>
                      </Col>

                      <Col className="col-auto">
                        <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                          <i className="ni ni-bullet-list-67" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  onClick={() => navigate("/admin/users")}
                  style={{ cursor: "pointer" }}
                >
                  <CardBody>
                    <Row>
                      <Col className="d-flex align-items-center">
                        <CardTitle
                          tag="h4"
                          className="text-uppercase text-muted mb-0 align-center"
                        >
                          Users
                        </CardTitle>
                      </Col>

                      <Col className="col-auto">
                        <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                          <i className="ni ni-single-02 " />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  onClick={() => navigate("/admin/coins-holder")}
                  style={{ cursor: "pointer" }}
                >
                  <CardBody>
                    <Row>
                      <Col className="d-flex align-items-center">
                        <CardTitle
                          tag="h4"
                          className="text-uppercase text-muted mb-0 align-center"
                        >
                          Coins Holder
                        </CardTitle>
                      </Col>

                      <Col className="col-auto">
                        <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                          <i className="ni ni-shop " />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <Row style={{ marginTop: "20px" }}>
            <Col lg="6">
              <Card
                className="card-stats mb-4 mb-xl-0"
                onClick={() => navigate("/admin/vesting-list")}
                style={{ cursor: "pointer" }}
              >
                <CardBody>
                  <Row>
                    <Col className="d-flex align-items-center">
                      <CardTitle
                        tag="h5"
                        style={{ color: "#000000" }}
                        className="text-uppercase text-muted mb-0 align-center"
                      >
                        Date
                      </CardTitle>
                    </Col>
                    <Col className="d-flex align-items-center">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 align-center"
                      >
                        SUM of MEF
                      </CardTitle>
                    </Col>
                    <Col className="d-flex align-items-center">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 align-center"
                      >
                        Transactions
                      </CardTitle>
                    </Col>
                  </Row>
                  <hr />

                  {filteredData?.map((item, index) => (
                    <Row style={{ marginTop: "20px" }}>
                      <Col className="d-flex align-items-center">
                        <CardTitle
                          tag="h4"
                          className="text-uppercase text-muted mb-0 align-center"
                        >
                          {moment(item[0].transactionDate).format("YYYY-MM-DD")}
                        </CardTitle>
                      </Col>
                      <Col className="d-flex align-items-center">
                        <CardTitle
                          tag="h4"
                          className="text-uppercase text-muted mb-0 align-center"
                        >
                          {formatNumberWithCommas(item.reduce((acc, cur) => acc + cur?.amount, 0))} MEF
                        </CardTitle>
                      </Col>
                      <Col className="d-flex align-items-center">
                        <CardTitle
                          tag="h4"
                          className="text-uppercase text-muted mb-0 align-center"
                        >
                          {item.length}
                        </CardTitle>
                      </Col>
                    </Row>
                  ))}
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" xl="3">
              <Card
                className="card-stats mb-4 mb-xl-0"
                onClick={() => navigate("/admin/vesting-list")}
                style={{ cursor: "pointer", minHeight: 284 }}
              >
                <CardBody>
                  <p style={{ fontSize: 24, fontWeight: '600', textAlign: 'center', }}>All required MEF</p>

                  <div style={{ width: '100%', height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    <p style={{ fontSize: 24, fontWeight: '600', textAlign: 'center', color: '#000' }}>{formatNumberWithCommas(totalAmount)} MEF</p>

                  </div>

                </CardBody>
              </Card>

            </Col>

          </Row>

        </Container>
      </div>
    </>
  );
};

export default Header;
