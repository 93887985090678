import { client } from "./api-client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetCoins = (queryParams) =>
  useQuery(["getCoins", queryParams], async () => {
    let queryString = "dashboard/getCoins";
    if (queryParams.name) {
      queryString += `?name=${queryParams.name}`;
    }
    if (queryParams.page != null) {
      queryString += queryParams.name
        ? `&page=${queryParams.page}`
        : `?page=${queryParams.page}`;
    }
    if (queryParams.limit != null) {
      queryString +=
        queryParams.name || queryParams.page != null
          ? `&limit=${queryParams.limit}`
          : `?limit=${queryParams.limit}`;
    }
    const response = await client(queryString);

    return response;
  });

export const useAddCoin = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (newCoinsData) => {
      const response = await client("dashboard/addCoin", {
        method: "POST",
        data: newCoinsData, // Assuming newCoinData contains the data for adding a coin
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getCoins"]); // Invalidate the cache for getCoins query
      },
    }
  );
};

export const usePutPoints = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (putPointsData) => {
      const response = await client("dashboard/put-points", {
        method: "POST",
        data: putPointsData,
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getCoins"]);
      },
    }
  );
};



export const usePutVesting = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (putVestingData) => {
      const response = await client("dashboard/put-vesting", {
        method: "POST",
        data: putVestingData,
      });
      return response;
    },

    {
      // onSuccess: () => {
      //   queryClient.invalidateQueries(["getCoins"]);
      // },
    }
  );
};



export const useEditCoin = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ coinId, newCoinsData }) => {
      const response = await client(`dashboard/editCoin/${coinId}`, {
        method: "POST",
        data: newCoinsData,
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getCoins"]); // Invalidate the cache for getCoins query
      },
    }
  );
};


export const useDeleteCoin = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (coinId) => {
      const response = await client(`dashboard/deleteCoinById/${coinId}`, {
        method: "POST",
        // data: coinId,
      });
      return response;
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getCoins"]); // Invalidate the cache for getCoins query
      },
    }
  );
};




export const useFindUserByPhone = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (phone) => {
      const response = await client(`auth/getUser/phone`, {
        method: "POST",
        data: phone,
      });
      return response;
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["getCoins"]); // Invalidate the cache for getCoins query
      },
    }
  )
}




export const useGetBalance = () =>
  useQuery("getBalance", async () => {
    const response = await client("balance/getBalance");
    return response;
  });

export const useGetBalanceByUserId = (userId) =>
  useQuery(["getBalanceByUserId", userId], async () => {
    const response = await client(`balance/getBalanceByUserId/${userId}`);
    return response;
  });

export const useGetUserList = ({ page, limit, phoneNumber }) => {
  return useQuery(
    ["getUserList", page, limit, phoneNumber],
    async () => {
      let queryString = `dashboard/getUserList?page=${page}&limit=${limit}&phoneNumber=${phoneNumber}`;
      const response = await client(queryString);
      return response;
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetCoinHolders = ({ phoneNumber, page, limit }) => {
  return useQuery(
    ["getCoinHolders", page, limit, phoneNumber],
    async () => {
      let queryString = `dashboard/getCoinHolders?phoneNumber=${phoneNumber}&page=${page}&limit=${limit}`;
      const response = await client(queryString);
      return response;
    },
    {
      keepPreviousData: true,
    }
  );
};



export const useGetVestingList = () => {
  return useQuery(
    ["getVestingList"],
    async () => {
      let queryString = `dashboard/getVestingList`;
      const response = await client(queryString);
      return response;
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetVestingListUser = () => {
  return useQuery(
    ["getVestingListUser"],
    async () => {
      let queryString = `dashboard/getVestingList/user`;
      const response = await client(queryString);
      return response;
    },
    {
      keepPreviousData: true,
    }
  );
};