import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { useQuery } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";

function App() {
  const { data: token } = useQuery(["token"], () =>
    localStorage.getItem("token")
  );

  if (token === undefined) {
    return <Spinner />;
  }

  return (
    <BrowserRouter>
      <Routes>
        {token ? (
          <Route path="/admin/*" element={<AdminLayout />} />
        ) : (
          <Route path="/auth/*" element={<AuthLayout />} />
        )}
        <Route
          path="*"
          element={
            token ? (
              <Navigate to="/admin/index" replace />
            ) : (
              <Navigate to="/auth/login" replace />
            )
          }
        />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
