import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Spinner,
} from "reactstrap";
import { useLoginAdmin } from "utils/auth.api";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const loginMutation = useLoginAdmin();
  const { isLoading } = loginMutation;

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      // Check if email or password is empty and return without making the API call
      return;
    }

    const loginData = {
      admin_email: email,
      admin_password: password,
    };

    try {
      await loginMutation.mutateAsync(loginData);
      navigate("/admin/index");
      setEmail(""); // Clear email input
      setPassword(""); // Clear password input
      toast.success("Logged In Successfully!");
    } catch (error) {
      toast.error("Login Failed", error);
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-light shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center mb-4">
              <small>Sign in With Email and Password</small>
            </div>
            <Form role="form" onSubmit={handleLogin}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="dark"
                  type="submit"
                  disabled={!email || !password || isLoading}
                >
                  {isLoading ? (
                    // <div className="spinner-border text-light" role="status">
                    //   <span className="sr-only">Loading...</span>
                    // </div>
                    <Spinner size="sm" />
                  ) : (
                    "Sign in"
                  )}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
