import CustomHeader from "components/Headers/CustomHeader";
import CustomTable from "components/Table/CustomTable";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Pagination,
  Row,
  Spinner,
} from "reactstrap";
import { useGetCoinHolders } from "utils/user.api";

const CoinsHolders = () => {
  const [coinsHolders, setCoinsHolders] = useState([]);
  const [dropdownStates, setDropdownStates] = useState(
    coinsHolders.map(() => false)
  );
  const [pages, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState(""); // Add state for search query
  const itemsPerPage = 10;

  // Function to toggle the dropdown for a specific row
  const toggleDropdown = (index) => {
    const updatedStates = [...dropdownStates];
    updatedStates[index] = !updatedStates[index];
    setDropdownStates(updatedStates);
  };

  const { isLoading, isError, data, isPreviousData } = useGetCoinHolders({
    phoneNumber: searchQuery,
    page: pages,
    limit: itemsPerPage,
  });

  const columns = [
    "User Name",
    "Wallet Address",
    "No of Coins",
    "Phone Number",
  ];

  const handlePageChange = (selectedPage) => {
    console.log("selected", selectedPage);
    const newPage = selectedPage.selected;
    // setSearchQuery("");

    if (newPage < pages) {
      // setPage((old) => Math.max(old - 1, 0));
      setPage(newPage);
      return;
    }

    if (newPage > pages) {
      if (!isPreviousData) {
        setPage(newPage);
        return;
      }
    }

    setCurrentPage(newPage);
  };
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredRows = coinsHolders.filter((holder) =>
    holder.phoneNumber.includes(searchQuery)
  );

  const displayedRows = filteredRows
    ?.slice(startIndex, endIndex)
    .map((item, index) => [
      item.name,
      item.address,
      <Dropdown
        isOpen={dropdownStates[index]}
        toggle={() => toggleDropdown(index)}
      >
        <DropdownToggle caret>See Coins</DropdownToggle>
        <DropdownMenu>
          {item.balance?.map((balanceOption) => (
            <DropdownItem key={balanceOption.value}>
              <img src={balanceOption.coin.icon} alt={""} width="20px" />{" "}
              {balanceOption?.balanceWithAirdrop} {balanceOption.coin.symbol}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>,
      item.phoneNumber,
    ]);

  const paginationContainerStyles = {
    justifyContent: "flex-end", // Align pagination to the right
    fontSize: "12px",
  };

  useEffect(() => {
    if (data) {
      setCoinsHolders(data?.data);
      setTotalCount(data?.count);
    }
  }, [data]);

  return (
    <div>
      <CustomHeader />
      {isLoading ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner />
        </div>
      ) : isError ? (
        <div className="text-center mt-5">
          <p>An error occurred. Please try again later.</p>
        </div>
      ) : (
        <Container className="mt--7 mb-5" fluid>
          <Row>
            <div className="column w-100">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex justify-content-between align-items-center ">
                  <h3 className="mb-0">Coins Holder</h3>
                  <Row>
                    <Row className="align-items-center mt-1">
                      <Col className="d-flex mt-3 mt-md-0 justify-content-md-end">
                        <div className="d-block d-md-flex align-items-center mr-3">
                          <Label for="searchEntry" className="mr-2 mb-0">
                            Search
                          </Label>
                          <Input
                            id="searchEntry"
                            type="search"
                            value={searchQuery}
                            onChange={(e) => {
                              setPage(0);
                              setSearchQuery(e.target.value); // Update the search query state
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </CardHeader>
                <CustomTable columns={columns} rows={displayedRows} />
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                      style={paginationContainerStyles}
                    >
                      <ReactPaginate
                        previousLabel={
                          <i
                            class="previous fa fa-chevron-left"
                            aria-hidden="true"
                          ></i>
                        }
                        nextLabel={
                          <i
                            className="next fa fa-chevron-right"
                            aria-hidden="true"
                          ></i>
                        }
                        breakLabel={"..."}
                        pageCount={Math.ceil(totalCount / itemsPerPage)}
                        onPageChange={handlePageChange}
                        containerClassName={
                          "pagination justify-content-end mb-0"
                        }
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        nextClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                      />
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default CoinsHolders;
