import CustomHeader from "components/Headers/CustomHeader";
import CustomTable from "components/Table/CustomTable";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Pagination,
  Row,
  Spinner,
} from "reactstrap";
import { useGetUserList } from "utils/user.api";

const Users = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Add state for search query
  const [pages, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const { isLoading, isError, data, isPreviousData } = useGetUserList({
    page: pages,
    limit: itemsPerPage,
    phoneNumber: searchQuery,
  });

  const columns = ["name", "phone no", "address", "Action"];

  const handlePageChange = (selectedPage) => {
    console.log("selected", selectedPage);
    const newPage = selectedPage.selected;
    // setSearchQuery("");

    if (newPage < pages) {
      // setPage((old) => Math.max(old - 1, 0));
      setPage(newPage);
      return;
    }

    if (newPage > pages) {
      if (!isPreviousData) {
        setPage(newPage);
        return;
      }
    }

    setCurrentPage(newPage);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredRows = userList.filter((user) =>
    user.phoneNumber.includes(searchQuery)
  );

  const displayedRows = filteredRows.slice(startIndex, endIndex).map((user) => [
    user.name,
    user.phoneNumber,
    user.address,
    <Button tag={Link} to={`/admin/users/${user.id}`} color="dark" size="sm">
      View Assets
    </Button>,
  ]);

  const paginationContainerStyles = {
    justifyContent: "flex-end", // Align pagination to the right
    fontSize: "12px",
  };

  console.log(userList);

  useEffect(() => {
    if (data) {
      setUserList(data?.data);
      setTotalCount(data?.count);
    }
  }, [data]);

  return (
    <div>
      <CustomHeader />
      {isLoading ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner />
        </div>
      ) : isError ? (
        <div className="text-center mt-5">
          <p>An error occurred. Please try again later.</p>
        </div>
      ) : (
        <Container className="mt--7 mb-5" fluid>
          <Row>
            <div className="column w-100">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex justify-content-between align-items-center ">
                  <h3 className="mb-0">User List</h3>
                  <Row>
                    <Row className="align-items-center mt-1">
                      <Col className="d-flex mt-3 mt-md-0 justify-content-md-end">
                        <div className="d-block d-md-flex align-items-center mr-3">
                          <Label for="searchEntry" className="mr-2 mb-0">
                            Search
                          </Label>
                          <Input
                            id="searchEntry"
                            type="search"
                            value={searchQuery}
                            onChange={(e) => {
                              setPage(0);
                              setSearchQuery(e.target.value); // Update the search query state
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </CardHeader>

                <CustomTable columns={columns} rows={displayedRows} />

                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                      style={paginationContainerStyles}
                    >
                      <ReactPaginate
                        previousLabel={
                          <i
                            className="previous fa fa-chevron-left"
                            aria-hidden="true"
                          ></i>
                        }
                        nextLabel={
                          <i
                            className="next fa fa-chevron-right"
                            aria-hidden="true"
                          ></i>
                        }
                        breakLabel={"..."}
                        pageCount={Math.ceil(totalCount / itemsPerPage)}
                        onPageChange={handlePageChange}
                        containerClassName={
                          "pagination justify-content-end mb-0"
                        }
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        nextClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                      />
                    </Pagination>
                  </nav>
                </CardFooter>
                {/* </>
                )} */}
              </Card>
            </div>
          </Row>
        </Container>
      )}

      <Modal isOpen={openEditModal} contentLabel="Edit User">
        <ModalHeader>
          <h2>Edit User</h2>
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="name">User Name</Label>
              <Input type="text" name="name" id="name" />
            </FormGroup>
            <FormGroup>
              <Label for="name">Type</Label>
              <Input type="text" name="type" id="type" />
            </FormGroup>
            <FormGroup>
              <Label for="name">Address</Label>
              <Input type="text" name="address" id="address" />
            </FormGroup>
          </Form>
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => setOpenEditModal(false)}
              color="dark"
              className="mb-3"
              disabled={isLoading}
            >
              Submit
            </Button>
            <Button
              onClick={() => setOpenEditModal(false)}
              color="secondary"
              className="mb-3"
            >
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Users;
