const { QueryCache } = require("@tanstack/react-query");

const apiURL = "https://wallet.meflex.io";
// const apiURL = "https://staging.meflex.io";
// const apiURL = "https://meflex.blockyfy.com";
// const apiURL = "http://192.168.18.12:3101";
// const apiURL = 'http://localhost:3101';

const queryCache = new QueryCache({
  onError: (error) => {
    console.log(error);
  },
  onSuccess: (data) => {
    console.log(data);
  },
});

async function client(
  endpoint,
  { data, headers: customHeaders, ...customConfig } = {}
) {
  const token = localStorage.getItem("token");
  const config = {
    method: data ? "POST" : "GET",
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `${token}` : "",
      "Content-Type": data ? "application/json" : "",
      ...customHeaders,
    },
    ...customConfig,
  };

  return await window
    .fetch(`${apiURL}/${endpoint}`, config)
    .then(async (response) => {
      // const body = response.json();
      if (response.status === 401) {
        queryCache.clear();
        return await Promise.reject({ message: "Please re-authenticate." });
      }
      if (response.ok) {
        const jsonData = await response.json(); // Await the JSON parsing
        return jsonData;
      } else {
        const jsonData = await response.json(); // Await the JSON parsing
        return await Promise.reject(jsonData);
      }
    });
}

export { client };
