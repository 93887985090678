import { useQuery } from "@tanstack/react-query";
import { client } from "./api-client";

export const useGetInvestList = () => {
    return useQuery(
        ["getInvestLiset"],
        async () => {
            let queryString = `invest/getInvestList`;
            const response = await client(queryString);
            return response;
        },
        {
            keepPreviousData: true,
        }
    );
};