import CustomHeader from "components/Headers/CustomHeader";
import CustomTable from "components/Table/CustomTable";

import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardFooter,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Pagination,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { BiEdit } from "react-icons/bi";
import { useAddCoin } from "utils/user.api";
import { useGetCoins } from "utils/user.api";
import { useEditCoin } from "utils/user.api";
import { useGetUserList } from "utils/user.api";
import { usePutPoints } from "utils/user.api";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import Select from "react-select";
import { AiFillDelete } from "react-icons/ai";
import { useDeleteCoin } from "utils/user.api";
import moment from "moment";
import { useGetVestingList } from "utils/user.api";
import { useGetVestingListUser } from "utils/user.api";

const CoinsList = () => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [coinName, setCoinName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [coinsData, setCoinData] = useState([]);
  const [coinId, setCoinId] = useState("");
  const [pointsAmount, setPointsAmount] = useState(0);
  const [pages, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { data: vestingList, isLoading: vestingListLoading } = useGetVestingListUser()
  const [activeDetail ,setActiveDetail] = useState(null)

  const [newCoinData, setNewCoinData] = useState({
    name: "",
    type: "",
    contractAddress: "",
    gecko: "",
    walletPrivateKey: "",
    option: "withdrawal", // Default option
    withdrawal: true, // Default value for withdrawal
    date: "", // Default value for date
    airDrop: false, // Default value for airDrop
    quantity: "",
    imageUrl: "",
    isToken: true,
  });

  
  const addCoinMutation = useAddCoin({});
  const editCoinMutation = useEditCoin();
  const deleteCoinMutation = useDeleteCoin();
  const putPointsCoinMutation = usePutPoints();
  const { data, isPreviousData } = useGetCoins({
    name: "",
    page: pages,
    limit: itemsPerPage,
  });
  const userList = useGetUserList({
    page: 0,
    limit: Number.MAX_SAFE_INTEGER,
    phoneNumber: "",
  });


  function handleOpen(name, id, users) {
    setOpenModal(true);
    setCoinId(id);
    setCoinName(name);
  }


  function handleEditOpen(id) {
    setCoinId(id);
    setOpenEditModal(true);

    // Fetch the coin data for the selected coinId
    const selectedCoin = coinsData.find((coin) => coin.id === id);
    console.log("selected coins", selectedCoin);

    // Pre-fill the edit form with the existing coin data
    setNewCoinData({
      name: selectedCoin.name,
      type: selectedCoin.symbol,
      contractAddress: selectedCoin?.contractAddress,
      gecko: selectedCoin.coinNameId,
      walletPrivateKey: selectedCoin.walletPrivateKey,
      option: selectedCoin.lockPeriod ? "date" : "withdrawal",
      withdrawal: selectedCoin.withdrawal,
      date: selectedCoin.lockPeriod,
      airDrop: selectedCoin.airDrop,
      quantity: selectedCoin.airDropQuantity,
      imageUrl: selectedCoin.icon,
      isToken: selectedCoin?.isToken,
    });
  }

  async function handleDelete(id) {
    try {
      await deleteCoinMutation.mutateAsync(id);
      toast.success("Coin Deleted Successfully!!!");
    } catch (error) {
      setIsError(true);
      toast.error(`error: ${error}`);
    }
  }

  const isValidURL = (url) => {
    // Regular expression pattern to match URLs
    const urlPattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
    return urlPattern.test(url);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (isValidURL(newCoinData?.imageUrl)) {
      const newCoinsData = {
        name: newCoinData.name,
        symbol: newCoinData.type,
        coinNameId: newCoinData.gecko,
        contractAddress: newCoinData?.contractAddress,
        walletPrivateKey: newCoinData.walletPrivateKey,
        withdrawal: newCoinData.withdrawal,
        lockPeriod: newCoinData.date,
        airDrop:
          newCoinData?.walletPrivateKey === "" ||
          newCoinData?.walletPrivateKey === "PRIVATE_KEY_0"
            ? false
            : newCoinData.airDrop,
        airDropQuantity:
          newCoinData?.walletPrivateKey === "" ||
          newCoinData?.walletPrivateKey === "PRIVATE_KEY_0"
            ? 0
            : newCoinData.quantity,
        icon: newCoinData.imageUrl,
        isDeleted: false,
        isActive: true,
      };
      setIsLoading(true);

      try {
        await editCoinMutation.mutateAsync({ coinId, newCoinsData });
        setNewCoinData({
          name: "",
          type: "",
          gecko: "",
          contractAddress: "",
          private: "",
          option: "withdrawal",
          withdrawal: true,
          date: "",
          airDrop: false,
          quantity: "",
          imageUrl: "",
        });
        toast.success("Coin Edit Successfully");
        setOpenEditModal(false);
      } catch (error) {
        setIsError(true);
        toast.error(`Error: ${error}`);
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error("Please use valid image URL!");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(newCoinData.airDrop);
    if (isValidURL(newCoinData?.imageUrl)) {
      const newCoinsData = {
        name: newCoinData.name,
        symbol: newCoinData.type,
        contractAddress: newCoinData?.contractAddress,
        coinNameId: newCoinData.gecko,
        walletPrivateKey: newCoinData.walletPrivateKey,
        withdrawal: newCoinData.withdrawal,
        lockPeriod: newCoinData.date,
        airDrop:
          newCoinData?.walletPrivateKey === "" ||
          newCoinData?.walletPrivateKey === "PRIVATE_KEY_0"
            ? false
            : newCoinData.airDrop,
        airDropQuantity:
          newCoinData?.walletPrivateKey === "" ||
          newCoinData?.walletPrivateKey === "PRIVATE_KEY_0"
            ? 0
            : newCoinData.quantity,
        isDeleted: false,
        isActive: true,
        icon: newCoinData.imageUrl,
        isToken: true,
      };
      console.log(newCoinsData?.airDrop);

      setIsLoading(true);

      try {
        await addCoinMutation.mutateAsync(newCoinsData);
        setNewCoinData({
          name: "",
          type: "",
          contractAddress: "",
          gecko: "",
          private: "",
          option: "withdrawal",
          withdrawal: true,
          date: "",
          airDrop: false,
          quantity: "",
          imageUrl: "",
          isToken: true,
        });
        setOpenAddModal(false);
        toast.success("Coin added successfully");
      } catch (e) {
        setIsError(true);
        toast.error(e?.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error("Please use valid image URL!");
    }
  };


  const columns = [
    "Put Date",
    "User phone number",
    "Amount",
    "For",
    "Start Date",
    "Percentage",
    // "Action",
    // "Airdrop",
    // "action",
  ];


  const handlePageChange = (selectedPage) => {
    console.log("selected", selectedPage);
    const newPage = selectedPage.selected;

    if (newPage < pages) {
      // setPage((old) => Math.max(old - 1, 0));
      setPage(newPage);
      return;
    }

    if (newPage > pages) {
      if (!isPreviousData) {
        setPage(newPage);
        return;
      }
    }

    setCurrentPage(newPage);
  };
    

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const displayedRows =  vestingList?.length > 0 ? vestingList?.slice(startIndex, endIndex)?.map((val) => [
      // <img src={coin?.icon} alt="icon" width={30} height={30} />,
      moment(val?.createAt).format("YYYY-MM-DD HH:mm:ss"),
      val?.phone,
      val?.amount,
      val?.transactionDate? '30 days': 'Now',
      moment(val?.transactionDate).format("YYYY-MM-DD HH:mm:ss"),
      val?.percentage ? `${val?.percentage}%` : '-',
      // val.vestingDetails,
  ]) : [];
    
  const paginationContainerStyles = {
    justifyContent: "flex-end", // Align pagination to the right
    fontSize: "12px",
  };

  const options = userList?.data?.data?.map((user) => ({
    value: user.id,
    label: user.phoneNumber,
  }));

  const filteredOptions = options?.filter((option) => {
    return option.label.includes(searchTerm);
  });

  useEffect(() => {
    if (newCoinData.option === "withdrawal") {
      setNewCoinData({
        ...newCoinData,
        date: "",
        option: "withdrawal",
      });
    } else {
      setNewCoinData({
        ...newCoinData,
        withdrawal: false,
        option: "date",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCoinData.option]);

  useEffect(() => {
    if (data) {
      setCoinData(data?.data);
      setTotalCount(data?.count);
    }
  }, [data]);

  function getMinDate() {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1; // Months are 0-indexed
    const year = today.getFullYear();

    // Format day and month with leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Return the minimum date in the "yyyy-mm-dd" format
    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  // const formattedDate = new Date(newCoinData.date).toISOString().split('T')[0];
  // const formattedDate = new Date(newCoinData.date).toISOString().split('T')[0];

    
    
  function formatNumberWithCommas(number) {
    // Use toLocaleString to add commas
    if (!number) return 0
    return Number(number).toLocaleString();
  }
function getOrdinal(number) {
        if (typeof number !== 'number' || isNaN(number)) {
            return 'Invalid input';
        }

        // Handle special cases for 11th, 12th, and 13th
        if (number % 100 >= 11 && number % 100 <= 13) {
            return number + 'th';
        }

        // Regular cases for 1st, 2nd, 3rd, 4th, etc.
        switch (number % 10) {
            case 1:
                return number + 'st';
            case 2:
                return number + 'nd';
            case 3:
                return number + 'rd';
            default:
                return number + 'th';
        }
    }
  return (
    <div>
      <CustomHeader />
      {isError ? (
        <div className="text-center mt-5">
          <p>An error occurred. Please try again later.</p>
        </div>
      ) : isLoading ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner />
        </div>
      ) : (
                      <Container className="mt--7 mb-5" fluid>
                          
                          {vestingListLoading ? (
                            <div className="d-flex justify-content-center mt-5">
                              <Spinner />
                            </div>) :
          <Row>
            <div className="column w-100">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex justify-content-between align-items-center ">
                  <h3 className="mb-0">Vesting List</h3>
                      </CardHeader>
                      
                      <Table className="align-items-center table-flush" responsive>
                           <thead className="thead-light">
        <tr>
          {columns.map((column, index) => (
            <th key={index} scope="col">
              {column}
            </th>
          ))}
                  <th scope="col" />
                      </tr>
                        </thead>
                        <tbody>
                          {vestingList.map((val, i) => (
                            <>
                            <tr style={{cursor: 'pointer'}} onClick={() => setActiveDetail(val?._id)} key={i}>
                              <td>{moment(val.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                              <td>{val.phone}</td>
                              <td>{val.amount} MEF</td>
                              <td>{Number(val.percentage) > 0 ? '30 days': 'Now'}</td>
                              <td>{moment(val.transactionDate).format("YYYY-MM-DD HH:mm")}</td>
                                <td>{!val.percentage || val.percentage == '0' ?  'Now'  :val.percentage + '%'} </td>
                              </tr>
                              {
                               activeDetail == val?.vestingDetails[0]?.vestingId && 
                                val?.vestingDetails.map((detail, index) => (
                                  <tr key={index} style={{backgroundColor:'#EDEDED'}}>
                                    <td>-</td>
                                    <td><div style={{ width: 'fit-content', padding: 3, paddingInline: 5, borderRadius: 15, backgroundColor: detail.status === "PENDING" ? '#FFF3F3' : "#9FA7E8", color: detail.status === "PENDING" ? 'red' : "#3543BF" }}>{detail?.status}</div></td>
                                    <td>{ detail?.amount} MEF</td>
                                    <td>{getOrdinal(index + 1)} payment</td>
                                    <td>{moment(detail.transactionDate || detail?.createdAt).format("YYYY-MM-DD")}</td>
                                    <td>{detail?.percentage}%</td>
                                  </tr>
                                  ))
                              }
                            </>
                            )
                          )}
                         
                    </tbody>

                          </Table>
                {/* <CustomTable columns={columns} rows={displayedRows} /> */}
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                      style={paginationContainerStyles}>
                                                  
                      <ReactPaginate
                        previousLabel={
                          <i
                            class="previous fa fa-chevron-left"
                            aria-hidden="true"
                          ></i>
                        }
                        nextLabel={
                          <i
                            className="next fa fa-chevron-right"
                            aria-hidden="true"
                          ></i>
                        }
                        breakLabel={"..."}
                        pageCount={Math.ceil(totalCount / itemsPerPage)}
                        onPageChange={handlePageChange}
                        containerClassName={
                          "pagination justify-content-end mb-0"
                        }
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        activeClassName={"active"}
                        previousClassName={"page-item"}
                        nextClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                      />
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
                          }
                              
        </Container>
      )}
      <Modal isOpen={openAddModal} contentLabel="Add Coin Modal">
        {/* <Container> */}
        <ModalHeader>
          <h2>Add Coin</h2>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            {/* Your form fields here */}
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                required
                type="text"
                name="name"
                id="name"
                placeholder="Coin Name"
                value={newCoinData.name}
                onChange={(e) =>
                  setNewCoinData({ ...newCoinData, name: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="type">Coin Ticker/Symbol</Label>
              <Input
                required
                type="text"
                name="type"
                id="type"
                placeholder="Coin Ticker/Symbol"
                value={newCoinData.type}
                onChange={(e) =>
                  setNewCoinData({ ...newCoinData, type: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="type">Contract Address</Label>
              <Input
                required={!newCoinData?.withdrawal ? true : false}
                type="text"
                name="type"
                id="type"
                placeholder="Contract Address"
                value={newCoinData.contractAddress}
                onChange={(e) =>
                  setNewCoinData({
                    ...newCoinData,
                    contractAddress: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="gecko">Coin Gecko ID</Label>
              <Input
                type="text"
                name="gecko"
                id="gecko"
                placeholder="Gecko Id"
                value={newCoinData.gecko}
                onChange={(e) =>
                  setNewCoinData({ ...newCoinData, gecko: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="private">Private Key ID</Label>
              <Input
                required={!newCoinData?.withdrawal ? true : false}
                type="text"
                name="private"
                id="private"
                placeholder="Private Key ID"
                value={newCoinData.walletPrivateKey}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    e.target.value === "PRIVATE_KEY_0"
                  ) {
                    setNewCoinData({
                      ...newCoinData,
                      walletPrivateKey: e.target.value,
                      airDrop: false,
                      quantity: "",
                    });
                  } else {
                    setNewCoinData({
                      ...newCoinData,
                      walletPrivateKey: e.target.value,
                    });
                  }
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="imageUrl">Image URL</Label>
              <Input
                required
                type="text"
                name="imageUrl"
                id="imageUrl"
                placeholder="Image URL"
                value={newCoinData.imageUrl}
                onChange={(e) =>
                  setNewCoinData({ ...newCoinData, imageUrl: e.target.value })
                }
              />
            </FormGroup>
            <div className="d-flex flex-column">
              <FormGroup check className="">
                <Label check className="d-flex justify-content-between">
                  <Input
                    required
                    type="radio"
                    name="option"
                    value="withdrawal"
                    checked={newCoinData.option === "withdrawal"}
                    onChange={(e) =>
                      setNewCoinData({ ...newCoinData, option: "withdrawal" })
                    }
                  />
                  Withdrawal locked
                  {newCoinData.option === "withdrawal" && (
                    <FormGroup className="">
                      <Label className="custom-toggle custom-toggle-primary">
                        <Input
                          type="checkbox"
                          checked={newCoinData.withdrawal}
                          onChange={() =>
                            setNewCoinData({
                              ...newCoinData,
                              withdrawal: !newCoinData.withdrawal,
                            })
                          }
                        />
                        <span className="custom-toggle-slider rounded-circle"></span>
                      </Label>
                    </FormGroup>
                  )}
                </Label>
              </FormGroup>
              <FormGroup check className="mb-5">
                <Label
                  check
                  className={`d-flex flex-column justify-content-between ${
                    newCoinData.option === "withdrawal" ? "mt--4" : "mt-3"
                  }`}
                >
                  <Input
                    required
                    type="radio"
                    name="option"
                    value="date"
                    checked={newCoinData.option === "date"}
                    onChange={(e) =>
                      setNewCoinData({ ...newCoinData, option: "date" })
                    }
                  />
                  Locked Period
                  {newCoinData.option === "date" && (
                    <FormGroup>
                      {/* <Label for="selectedDate"></Label> */}
                      <Input
                        type="date"
                        name="selectedDate"
                        id="selectedDate"
                        min={getMinDate()}
                        value={newCoinData.date}
                        onChange={(e) =>
                          setNewCoinData({
                            ...newCoinData,
                            date: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  )}
                </Label>
              </FormGroup>
            </div>
            <FormGroup
              className={`d-flex justify-content-between ml-2 ${
                newCoinData.option === "withdrawal" ? "mt--4" : "mt--5"
              }`}
            >
              <Label for="airdrop">Airdrop (on-chain)</Label>
              <Label className="custom-toggle custom-toggle-primary ml-3">
                <Input
                  name="airdrop"
                  id="airdrop"
                  type="checkbox"
                  checked={newCoinData.airDrop}
                  disabled={
                    !newCoinData?.contractAddress ||
                    !newCoinData?.walletPrivateKey ||
                    newCoinData?.walletPrivateKey === "PRIVATE_KEY_0"
                  }
                  onChange={() => {
                    if (
                      !(
                        !newCoinData?.contractAddress ||
                        !newCoinData?.walletPrivateKey ||
                        newCoinData?.walletPrivateKey === "PRIVATE_KEY_0"
                      )
                    ) {
                      setNewCoinData({
                        ...newCoinData,
                        airDrop: !newCoinData.airDrop,
                      });
                    }
                  }}
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="OFF"
                  data-label-on="ON"
                ></span>
              </Label>
            </FormGroup>
            {newCoinData.airDrop && (
              <FormGroup className="ml-2">
                <Label for="quantity">Airdrop Quantity</Label>
                <Input
                  required
                  type="number"
                  name="quantity"
                  id="quantity"
                  placeholder="Quantity"
                  value={newCoinData.quantity}
                  onChange={(e) =>
                    setNewCoinData({ ...newCoinData, quantity: e.target.value })
                  }
                />
              </FormGroup>
            )}

            <div className="d-flex justify-content-end">
              {isLoading ? (
                <Button className="mb-3">
                  <Spinner size="sm" />
                </Button>
              ) : (
                <Button
                  color="dark"
                  className="mb-3"
                  type="submit"
                  disabled={isLoading}
                >
                  Submit
                </Button>
              )}

              <Button
                onClick={() => {
                  setOpenAddModal(false);
                }}
                color="secondary"
                className="mb-3"
                disabled={isLoading}
              >
                Close
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={openEditModal} contentLabel="Edit Coin">
        <ModalHeader>
          <h2>Edit Coin</h2>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleEditSubmit}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                required
                type="text"
                name="name"
                id="name"
                placeholder="Coin Name"
                value={newCoinData.name}
                onChange={(e) =>
                  setNewCoinData({ ...newCoinData, name: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="type">Coin Ticker/Symbol</Label>
              <Input
                required
                type="text"
                name="type"
                id="type"
                placeholder="Coin Ticker/Symbol"
                value={newCoinData.type}
                onChange={(e) =>
                  setNewCoinData({ ...newCoinData, type: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="gecko">Coin Gecko ID</Label>
              <Input
                type="text"
                name="gecko"
                id="gecko"
                placeholder="Gecko Id"
                value={newCoinData.gecko}
                onChange={(e) =>
                  setNewCoinData({ ...newCoinData, gecko: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="type">Contract Address</Label>
              <Input
                required={!newCoinData?.withdrawal ? true : false}
                type="text"
                name="type"
                id="type"
                placeholder="Contract Address"
                value={newCoinData.contractAddress}
                onChange={(e) =>
                  setNewCoinData({
                    ...newCoinData,
                    contractAddress: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="private">Private Key ID</Label>
              <Input
                required
                type="text"
                name="private"
                id="private"
                placeholder="Private Key"
                value={newCoinData.walletPrivateKey}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    e.target.value === "PRIVATE_KEY_0"
                  ) {
                    setNewCoinData({
                      ...newCoinData,
                      walletPrivateKey: e.target.value,
                      airDrop: false,
                      quantity: "",
                    });
                  } else {
                    setNewCoinData({
                      ...newCoinData,
                      walletPrivateKey: e.target.value,
                    });
                  }
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="imageUrl">Image URL</Label>
              <Input
                required
                type="text"
                name="imageUrl"
                id="imageUrl"
                placeholder="Image URL"
                value={newCoinData.imageUrl}
                onChange={(e) =>
                  setNewCoinData({ ...newCoinData, imageUrl: e.target.value })
                }
              />
            </FormGroup>
            <div className="d-flex flex-column">
              <FormGroup check className="">
                <Label check className="d-flex justify-content-between">
                  <Input
                    required
                    type="radio"
                    name="option"
                    value="withdrawal"
                    checked={newCoinData.option === "withdrawal"}
                    onChange={(e) =>
                      setNewCoinData({ ...newCoinData, option: "withdrawal" })
                    }
                  />
                  Withdrawal locked
                  {newCoinData.option === "withdrawal" && (
                    <FormGroup className="">
                      <Label className="custom-toggle custom-toggle-primary">
                        <Input
                          type="checkbox"
                          checked={newCoinData.withdrawal}
                          onChange={() =>
                            setNewCoinData({
                              ...newCoinData,
                              withdrawal: !newCoinData.withdrawal,
                            })
                          }
                        />
                        <span className="custom-toggle-slider rounded-circle"></span>
                      </Label>
                    </FormGroup>
                  )}
                </Label>
              </FormGroup>
              <FormGroup check className="mb-5">
                <Label
                  check
                  className={`d-flex flex-column justify-content-between ${
                    newCoinData.option === "withdrawal" ? "mt--4" : "mt-3"
                  }`}
                >
                  <Input
                    required
                    type="radio"
                    name="option"
                    value="date"
                    checked={newCoinData.option === "date"}
                    onChange={(e) =>
                      setNewCoinData({ ...newCoinData, option: "date" })
                    }
                  />
                  Locked Periods
                  {newCoinData?.option === "date" && (
                    <FormGroup>
                      {/* <Label for="selectedDate"></Label> */}

                      <Input
                        type="date"
                        name="selectedDate"
                        id="selectedDate"
                        min={getMinDate()}
                        // value={new Date(newCoinData?.date)?.toISOString().split('T')[0]}
                        value={
                          newCoinData.date
                            ? new Date(newCoinData.date)
                                ?.toISOString()
                                .split("T")[0]
                            : "" // Handle empty or invalid date
                        }
                        onChange={(e) =>
                          setNewCoinData({
                            ...newCoinData,
                            date: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  )}
                </Label>
              </FormGroup>
            </div>
            <FormGroup
              className={`d-flex justify-content-between  ${
                newCoinData.option === "withdrawal" ? "mt--4" : "mt--5"
              }`}
            >
              <Label for="airdrop">Airdrop (on-chain)</Label>
              <Label className="custom-toggle custom-toggle-primary ml-3">
                <Input
                  name="airdrop"
                  id="airdrop"
                  type="checkbox"
                  checked={newCoinData.airDrop}
                  disabled={
                    !newCoinData?.contractAddress ||
                    !newCoinData?.walletPrivateKey ||
                    newCoinData?.walletPrivateKey === "PRIVATE_KEY_0"
                  }
                  onChange={() => {
                    if (
                      !newCoinData?.contractAddress ||
                      !newCoinData?.walletPrivateKey ||
                      newCoinData?.walletPrivateKey === "PRIVATE_KEY_0"
                    ) {
                    } else {
                      setNewCoinData({
                        ...newCoinData,
                        airDrop: !newCoinData.airDrop,
                      });
                    }
                  }}
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="OFF"
                  data-label-on="ON"
                ></span>
              </Label>
            </FormGroup>
            {newCoinData.airDrop && (
              <FormGroup className="">
                <Label for="quantity">Airdrop Quantity</Label>
                <Input
                  required
                  type="number"
                  name="quantity"
                  id="quantity"
                  placeholder="Quantity"
                  value={newCoinData.quantity}
                  onChange={(e) =>
                    setNewCoinData({ ...newCoinData, quantity: e.target.value })
                  }
                />
              </FormGroup>
            )}

            <div className="d-flex justify-content-end">
              {isLoading ? (
                <Button className="mb-3">
                  <Spinner size="sm" />
                </Button>
              ) : (
                <Button
                  color="dark"
                  className="mb-3"
                  type="submit"
                  disabled={isLoading}
                >
                  Submit
                </Button>
              )}

              <Button
                onClick={() => {
                  setOpenEditModal(false);
                  setNewCoinData({
                    name: "",
                    type: "",
                    gecko: "",
                    private: "",
                    option: "withdrawal",
                    withdrawal: true,
                    date: "",
                    airDrop: false,
                    quantity: "",
                  });
                }}
                color="secondary"
                className="mb-3"
                disabled={isLoading}
                // size="sm"
              >
                Close
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={openModal} contentLabel="Airdrop Coin">
        <ModalHeader>
          <h2>Put Points (off-chain)</h2>
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              console.log(selectedUser.value);
              if (!selectedUser || pointsAmount <= 0) {
                toast.error(
                  "Please fill in all fields and ensure the amount is greater than zero."
                );
                return;
              }

              const putPointsData = {
                userId: selectedUser.value,
                coinId: coinId,
                amount: Number(pointsAmount),
              };

              // console.log(putPointsData);
              try {
                await putPointsCoinMutation.mutateAsync(putPointsData);

                setSelectedUser(null);
                setCoinId("");
                setPointsAmount(0);
                setOpenModal(false);
                toast.success("Airdrop Added Successfully");
              } catch (error) {
                toast.error(`Error ${error}`);
              }
            }}
          >
            <FormGroup>
              <Label for="name">Meflex</Label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder={coinName}
                disabled
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="user">User Select</Label>
              <Select
                id="user"
                options={filteredOptions}
                value={selectedUser}
                onChange={(user) => setSelectedUser(user)}
                onInputChange={(inputValue) => setSearchTerm(inputValue)} // Update search term state
                placeholder="Search or Select User"
                isSearchable={true}
                isClearable={true}
                noOptionsMessage={() => "No users found"} // Message when no options match the search term
              />
            </FormGroup>
            <FormGroup>
              <Label for="name">Amount</Label>
              <Input
                type="number"
                name="amount"
                id="amount"
                value={pointsAmount}
                onChange={(e) => setPointsAmount(e.target.value)}
              />
            </FormGroup>
            <div className="d-flex justify-content-end">
              <Button
                color="dark"
                className="mb-3"
                type="submit"
                disabled={isLoading}
              >
                Put points
              </Button>
              <Button
                onClick={() => {
                  setOpenModal(false);
                  setPointsAmount(0);
                }}
                color="secondary"
                className="mb-3"
              >
                Close
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CoinsList;
