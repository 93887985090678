// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @tailwind base; */
/* @tailwind components; */
/* @tailwind utilities; */`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB,0BAA0B;AAC1B,yBAAyB","sourcesContent":["/* @tailwind base; */\n/* @tailwind components; */\n/* @tailwind utilities; */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
