import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types'
import { useFindUserByPhone } from 'utils/user.api';
import { usePutVesting } from 'utils/user.api';
import {toast}from 'react-toastify'
import { Tooltip } from 'react-tooltip';
const VestingModal = ({openModal, coinName, setVestingModal, coinId }) => {

    const { mutate: findUser, data: userData } = useFindUserByPhone()
    const { mutate: putVesting , isError, error, isSuccess, isLoading} = usePutVesting()

    const [user, setUser] = useState({
        phone: '',
        address: ''
    });

    const [isActive, setIsActive] = useState(false)
    const [putVestingData, setPutVestingData] = useState({
        userId: null,
        address: '',
        coinId: '',
        amount: 0,
        for: null,
        startDate: null,
        percentage: 0,
          phone: '',
      password: '',
        isLastPercentageChecked: false
  });
    
    const handleSearchUser= async () => {
        findUser({ phone: user?.phone })
    }


    useEffect(() => {
        if(isSuccess){
            resetAll()
            setVestingModal(false)
            toast.success('Vesting Successfully')
        }
    },[isSuccess])


    useEffect(() => {
        if (userData) {
            setPutVestingData({ ...putVestingData, address: userData?.address, phone: userData?.phone, userId: userData?.userId })
            setIsActive(true)
        }
    }, [userData])

    
    const handlePutVesting = async (e) => {
        e.preventDefault()
        const params = {
            ...putVestingData,
            sendNow: !putVesting.for ? true : false,
            coinId,
        }

        console.log(params)
        putVesting(params)
        // resetAll()
        // setVestingModal(false)
    }


    useEffect(() => {
      if (error?.message) {
          console.log(error?.message)
            toast.error(error?.message)
        }

    },[isError])
    // Get today's date
    


    const resetAll = () => {
        setUser({
            phone: '',
            address: ''
        })
        setPutVestingData({
            userId: null,
            address: '',
            coinId: '',
            amount: 0,
            for: null,
            startDate: null,
            percentage: 0,
              phone: '',
          password: '',
            isLastPercentageChecked: false
        })
    } 

let today = new Date();

let nextDay = new Date(today);
nextDay.setDate(nextDay.getDate() + 1);

let nextDayString = nextDay.toISOString().slice(0, 10);




    return (
            <Modal isOpen={openModal} contentLabel="Airdrop Coin">
        <ModalHeader>
          <h2>Put Points (Vesting)</h2>
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={handlePutVesting}
          >
            <FormGroup>
              <Label htmlFor="Phone">User Select</Label>
              <div className="d-flex">
                <Input
                  value={user?.phone}
                  onChange={(e) => setUser({ ...user, phone: e.target.value })}
                  type="text"
                  name="Phone"
                  id="Phone"
                  style={{ backgroundColor: '#F5F5F5' }}
                                placeholder={'Phone number'}
                />
                <Button onClick={handleSearchUser} style={{ marginLeft: "10px", border: '1px solid #000' }}>
                  Search
                            </Button>
                            
                        </div>
                        <div className="mt-2">
                            <Label htmlFor='password'>Password</Label>
                            <Input
                                placeholder='Enter Password'
                                value={putVestingData.password}
                                onChange={(e) => setPutVestingData({ ...putVestingData, password: e.target.value })}
                                id='password'
                                type='password'
                                name='password'
                                />
                        </div>
              <div className="mt-2">
                <Label htmlFor="user">User</Label>
                <p>{putVestingData.phone}</p>
                <Label htmlFor="user">Wallet Address</Label>
                <p>{putVestingData?.address}</p>
              </div>
            </FormGroup>
            <FormGroup>

              <Label for="name">Meflex</Label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder={coinName}
                disabled
              />
            </FormGroup>

            <FormGroup>
              <Label for="name">Amount</Label>
              <Input
                type="number"
                name="amount"
                id="amount"
                value={putVestingData.amount}
                onChange={(e) => setPutVestingData({ ...putVestingData, amount: e.target.value })}
              />
            </FormGroup>
            <Label>For</Label>
            <ButtonGroup size="md" style={{ marginBottom: '20px', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Button style={{ border: !putVestingData.for ? '1px solid #000' : "", borderRadius: '5px', width: '40%', marginRight: '10px' }} onClick={() => setPutVestingData({ ...putVestingData, for: null, percentage: null, startDate: null, isLastPercentageChecked: false })}>
                Now
              </Button>
              <Button style={{ border: putVestingData.for ? '1px solid #000' : "", borderRadius: '5px', width: '40%', marginRight: '10px' }} onClick={() => setPutVestingData({ ...putVestingData, for: true })}>
                Later
              </Button>

            </ButtonGroup>

            {putVestingData.for &&
              <div>
                <FormGroup>
                  <Label for="startDate">Start date</Label>
                    <Input
                    min={nextDayString}
                    type="Date"
                    name="startDate"
                    id="startDate"
                    value={putVestingData.startDate}
                    onChange={(e) => setPutVestingData({ ...putVestingData, startDate: e.target.value })}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="precentage">Percentage per transaction</Label>
                                <Input
                                    max={100}
                                    maxLength={3}
                                    placeholder='Enter Precentage'
                    type="number"
                    name="precentage"
                                    id="precentage"
                                    step={0.1}
                    value={putVestingData.percentage}
                    onChange={(e) => setPutVestingData({ ...putVestingData, percentage: e.target.value > 100 ? 100 : e.target.value })}
                  />
                </FormGroup>

                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                
                  <input
                    style={{ width: '20px', height: '20px', borderRadius: '5px', border: '1px solid #000' }}
                    placeholder='Enter Precentage'
                    type="checkbox"
                    name="checkbox"
                    id="checkbox"
                    value={putVestingData.isLastPercentageChecked}
                    onChange={(e) => setPutVestingData({ ...putVestingData,  isLastPercentageChecked: e.target.checked })}
                  />
                  <span style={{fontSize: 14}}>Provide remaning values at once for the last month</span>
                  <a data-tooltip-id="my-tooltip">
                  <img style={{cursor: 'pointer'}} src='/info-icon.svg'/>
                    <Tooltip opacity={1} closeOnScroll closeOnEsc closeOnResize place='bottom' id="my-tooltip" openOnClick style={{ zIndex: 9999, backgroundColor: 'white', boxShadow: '0 0 0 1px rgba(0,0,0,.1), 0 4px 11px rgba(0,0,0,.1)'}}>
                      <div style={{ width: 250, padding: 3}}>
                        <p style={{fontSize: 14, fontWeight: 'bold', color: '#808080'}}>ex)if you provide 8% per month</p>
                        <p style={{fontSize: 14, color: '#808080'}}>8% paid every month, and in the last month, 8% plus the remaining 4% is paid all atonce.</p>
                      </div>
                  </Tooltip>
                    </a>
                </div>
              </div>
                    }
            <div className="d-flex justify-content-end mt-4">
                        <Button
                        isLoading={isLoading}
                color="dark"
                className="mb-3"
                type="submit"
                disabled={!isActive || isLoading || !putVestingData.amount || !putVestingData.address || !putVestingData.password} 
              >
                Put Vesting
              </Button>
              <Button
                            onClick={() => {
                  setVestingModal(false);
                  resetAll();
                // setPutVestingData({
                //   address: '',
                //   coinId: '',
                //   amount: 0,
                //   for: null,
                //   startDate: null,
                //   precentage: 0
                // });

                //   setPointsAmount(0);
                }}
                color="secondary"
                className="mb-3"
              >
                Close
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    )
}


export default VestingModal;


VestingModal.prototype = { 
coinName: PropTypes.string,
    openModal: PropTypes.bool,
    setVestingModal: PropTypes.func,
    // coinData: PropTypes.any,
    handlePutVesting: PropTypes.func,
    // phoneNumber: PropTypes.string,
    // address: PropTypes.string,
    // handleSearchUser: PropTypes.func,
    // putVestingData: PropTypes.any,
    // setPutVestingData: PropTypes.func,
    coinId: PropTypes.string
}