import { useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";

export const useCreateAdmin = () => {
  const createAdmin = async (adminData) => {
    const response = await client("auth/createAdmin", {
      method: "POST",
      data: adminData,
    });

    if (!response.ok) {
      throw new Error("Failed to create admin");
    }

    return response.data;
  };

  return useMutation(createAdmin);
};


export const useLoginAdmin = () => {
  const queryClient = useQueryClient();
  const loginAdmin = async (loginData) => {
    const response = await client("auth/loginAdmin", {
      method: "POST",
      data: loginData,
    });

    if (response.error) {
      // Assuming that your server returns an 'error' field when login fails
      throw new Error(response.error);
    }

    if (response.admin && response.token) {
      // Assuming that your server returns both data and a token when login is successful
      localStorage.setItem("token", response.token.access_token);
      // console.log(response)
      queryClient.invalidateQueries("token");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };

  return useMutation(loginAdmin);
};


