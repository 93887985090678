import CustomHeader from "components/Headers/CustomHeader";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import { useGetBalanceByUserId } from "utils/user.api";
import { useParams } from "react-router-dom";

const Assets = () => {
  const { id } = useParams();

  const { data: balanceData, isLoading, isError } = useGetBalanceByUserId(id);
  console.log(balanceData);

  return (
    <div>
      <CustomHeader />
      {isLoading ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner />
        </div>
      ) : isError ? (
        <div className="text-center mt-5">
          <p>An error occurred. Please try again later.</p>
        </div>
      ) : (
        <Container className="mt--7 mb-5" fluid>
          <Card>
            <CardHeader className="">
              <h2>Assets</h2>
            </CardHeader>
            <CardBody>
              {balanceData?.length > 0 ? (
                balanceData.map((bal) => (
                  <Row className="px-5 mt-2" key={bal.id}>
                    <Col xs="6" className="mb-3">
                      <h3 className="text-dark font-weight-bold">
                        {bal.coin.name}
                      </h3>
                      <h5 className="font-weight-light">{bal.address}</h5>
                      <div className="d-flex">
                        <img src={bal.coin.icon} alt="icon" height={25} />
                        <h4 className="ml-2">
                          {bal?.balanceWithAirdrop} {bal.coin.symbol}
                        </h4>
                      </div>
                    </Col>
                  </Row>
                ))
              ) : (
                <p>User don't have any assets yet.</p>
              )}
            </CardBody>
          </Card>
        </Container>
      )}
    </div>
  );
};

export default Assets;
