/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Assets from "views/examples/Assets";
import CoinsHolders from "views/examples/CoinsHolders";
import CoinsList from "views/examples/CoinsList";
import Login from "views/examples/Login.js";
import Users from "views/examples/Users";
import VestingList from "views/examples/VestingList";
import InvestingList from 'views/examples/InvestingList'
var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Index />,
    layout: "/admin",
    isMenu: true,
  },
  {
    path: "/coins-list",
    name: "Coins List",
    icon: "ni ni-bullet-list-67 text-blue",
    component: <CoinsList />,
    layout: "/admin",
    isMenu: true,
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-orange",
    component: <Users />,
    layout: "/admin",
    isMenu: true,
  },
  {
    path: "/users/:id",
    name: "Users",
    icon: "ni ni-single-02 text-orange",
    component: <Assets />,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/coins-holder",
    name: "Coins Holder",
    icon: "ni ni-shop text-yellow",
    component: <CoinsHolders />,
    layout: "/admin",
    isMenu: true,
  },
  {
    path: "/vesting-list",
    name: "Vesting List",
    icon: "ni ni-bullet-list-67 text-blue",
    component: <VestingList />,
    layout: "/admin",
    isMenu: true,
  },
  {
    path: "/investing-list",
    name: "Investing List",
    icon: "ni ni-bullet-list-67 text-blue",
    component: <InvestingList />,
    layout: "/admin",
    isMenu: true,
  },

  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: <Tables />,
  //   layout: "/admin",
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: <Register />,
  //   layout: "/auth",
  // },
];
export default routes;
